.container {
  display: flex;
  margin-top: 10px;
}


.link{
  text-decoration: none;
  color: inherit;
}

.subscriptionForm {
  align-content: center;
  width: 100%;
}

.subscriptionTitle {
  text-align: center;
  font-size: 35px;
  padding: 30px 0 50px;
}

.subscriptionTitle a {
  margin-top: -15px;
  display: block;
  color: black;
}