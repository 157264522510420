.featuredMetrics {
    width: 100%;
    /* display: flex; */
    justify-content: space-between;
    margin: 20px 30px;
    flex-wrap: wrap;
    align-content: center;
}

.featuredItem {
    flex: 1;
    margin: 30px auto 20px;
    padding: 30px;
    /* border-radius: 10px; */
    width: 800px;
    height: 100px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);

}

.futuredNameContainer {
    float: left;
    width: 170px;
}
.featuredDesc {
    float: right;
    width: 300px;
    color: gray;
}

.featuredShortDesc{
    font-size: 15px;
    color: gray;
    float: left;
    padding-top: 10px;
}

.featuredName {    
    font-size: 30px;
    float: left;
}

.featuredMetricValueContainer{
    display: flex;
    align-items: center;
    float: left;
}

.featuredMetricValue { 
    font-size: 30px;
    font-weight: 600;
}

.featuredMetricSplit {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.featuredMetricValue,
.featuredMetricSplit {
    word-break: break-word;
    width: 120px;
    display: flex;
} 